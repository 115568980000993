(function () {
    function loadScript() {
        if (!document.getElementById('webchat-container')) {
            const createChatContainer = document.createElement('div');
            createChatContainer.id = 'webchat-container';
            createChatContainer.classList.add('webchat-container');
            document.body.appendChild(createChatContainer);
        }

        var script = document.createElement('script');
        script.src = '/simplicity/webchat/webchat.bundle.js';
        script.type = 'text/javascript';
        document.body.appendChild(script);

        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/simplicity/webchat/webchat.bundle.css';
        link.type = 'text/css';
        document.head.appendChild(link);
    }

    loadScript();

})();
